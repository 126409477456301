import React from "react";
import Navbar from '../components/Navbar'
import { StaticQuery, graphql } from 'gatsby'

export const Header = (props) => <Navbar { ...props }  navigation={ props.navigation } />;

export default (props) => (
  <StaticQuery
    query={
      graphql`
        query headerQuery {
          markdownRemark(frontmatter: {templateKey: {eq: "header"}}) {          
          frontmatter {            
            nav_items {
              label
              linkObject {
                link
                isExternal
                childLinks {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
            nav_items_jp {
              label
              linkObject {
                link
                isExternal
                childLinks {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
            nav_items_zhhans {
              label
              linkObject {
                link
                isExternal
                childLinks {
                  label
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Header { ...props } navigation={ data.markdownRemark.frontmatter } />}
  />
);

