import React from "react";
import { StaticQuery, graphql, navigate, withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
import Header from '../templates/header';
import Footer from '../templates/footer';
import logo from '../img/H2-Group-logo.jpg'
import { isValidValue } from '../utils'

const Settings = ({ settings, children }) => {
  const location = !!children && !!children.props && !!children.props.location ? children.props.location : null;
  const firstTimeRedirect = () => {
    if (typeof window === 'undefined' || typeof navigator === `undefined` || typeof localStorage === `undefined`) {
      return null;
    }
    const firstVisit = !isValidValue(localStorage.getItem('htb_stored_language'));
    const lang = navigator && navigator.language;
    localStorage.setItem('htb_stored_language', "language_set");
    const pathArray = window.location.pathname.split('/');
    const path = pathArray[0] === "jp" || pathArray[0] === "zh-hant" || pathArray[0] === "zh-hans" ? pathArray.shift().join("/") : pathArray.join("/");
    if (firstVisit) {
      switch (lang) {
        case "ja":
          return navigate(`/jp${path}`);
        case "zh-CN":
        case "zh":
          return navigate(`/zh-hans${path}`);
        case "zh-TW":
          return navigate(`/zh-hant${path}`);
        default:
          return null;
      }
    }
    return null;
  };
  firstTimeRedirect();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{settings.title}</title>
        <meta name="description" content={settings.description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={settings.favicon}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={settings.favicon}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={settings.title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={logo} />
        <meta property="og:image:secure_url" content={logo}></meta>
        <link href="https://fonts.googleapis.com/css?family=Oswald" rel="stylesheet" />
        <script src="https://services.cognitoforms.com/scripts/embed.js"></script>
      </Helmet>
      <script id="mcjs" dangerouslySetInnerHTML={{ __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/c00f5861c978f3eea82bbf02f/2e62fb7c086cb6917acee4894.js");`}} />
      <Header settings={settings} location={location} />
      <div className="section content-container-section">{children}</div>
      <Footer location={location} />
    </div>
  );
}

export default ({ children }) => (
  <StaticQuery
    query={
      graphql`
        query SettingsQuery {
          markdownRemark(frontmatter: {templateKey: {eq: "settings"}}) {
          frontmatter {
            title
            description
            logo
            lightlogo
            favicon
          }
        }
      }
    `}
    render={(data) => <Settings children={children} settings={data.markdownRemark.frontmatter} />}
  />
);
